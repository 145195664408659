




































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Concejo extends Vue {
  private win = window;
  private base = window.location;
  private menu = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private listaConcejales = [
    {
      nombre: "Jacqueline Carolina Boero",
      email: "jacqueline.boero@municipalidadarica.cl",
      telefono: "432380231",
      anexo: "6231",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/jacqueline-boero.jpeg"
    },
    {
      nombre: "Dolores Paz Cautivo Ahumada",
      email: "dolores.cautivo@municipalidadarica.cl",
      telefono: "432380284",
      anexo: "6284",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/dolores-cautivo.jpeg"
    },
    {
      nombre: "Carolina Alejandra Medalla Alcayaga",
      email: "carolina.medalla@municipalidadarica.cl",
      telefono: "432380233",
      anexo: "6233",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/carolina-medalla.jpeg"
    },
    {
      nombre: "Víctor Sepúlveda Ilabaca",
      email: "victor.sepulveda@municipalidadarica.cl",
      telefono: "432380234",
      anexo: "6234",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/victor-sepulveda.jpeg"
    },
    {
      nombre: "Maximiliano Manríquez Pérez",
      email: "maximiliano.manriquez@municipalidadarica.cl",
      telefono: "432380232",
      anexo: "6232",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/maximiliano-manriquez.jpeg"
    },
    {
      nombre: "Cristian Remigio Rodríguez Sanhueza",
      email: "cristian.rodriguez@municipalidadarica.cl",
      telefono: "432380229",
      anexo: "6229",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/cristian-rodriguez.jpeg"
    },
    {
      nombre: "Mario Mamani Fernández",
      email: "mario.mamani@municipalidadarica.cl",
      telefono: "432380289",
      anexo: "6289",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/mario-mamani.jpeg"
    },
    {
      nombre: "Daniel Manríquez Zúñiga",
      email: "daniel.manriquez@municipalidadarica.cl",
      telefono: "432380235",
      anexo: "6235",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/daniel-manriquez.jpeg"
    },
    {
      nombre: "Susan Michell Vega Piñones",
      email: "susan.vega@municipalidadarica.cl",
      telefono: "432380230",
      anexo: "6230",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/susan-vega.jpeg"
    },
    {
      nombre: "Max Schauer Fernández",
      email: "max.schauer@municipalidadarica.cl",
      telefono: "432380238",
      anexo: "6238",
      nombreAsistente: "",
      telefonoAsistente: "",
      foto:
        "https://docs.muniarica.cl/web/media/imagenes/2024/12/max-schauer.jpeg"
    }
  ];
  private actasSesion: Array<any> = [];

  private mounted() {
    document.title = "Concejo Municipal - Municipalidad de Arica";
    this.setMenu();
    this.getActas();
  }

  private getActas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("actas/concejo")
      .then(res => {
        let base = process.env.VUE_APP_BASE_URL;
        this.actasSesion = res.data;
        for (let i = 0; i < this.actasSesion.length; i++) {
          for (let j = 0; j < this.actasSesion[i].actas_sesion.length; j++) {
            this.actasSesion[i].actas_sesion[j].url_archivo =
              base + this.actasSesion[i].actas_sesion[j].url_archivo;
          }
          for (let j = 0; j < this.actasSesion[i].actas_comision.length; j++) {
            this.actasSesion[i].actas_comision[j].url_archivo =
              base + this.actasSesion[i].actas_comision[j].url_archivo;
          }
          for (
            let j = 0;
            j < this.actasSesion[i].actas_extraordinarias.length;
            j++
          ) {
            this.actasSesion[i].actas_extraordinarias[j].url_archivo =
              base + this.actasSesion[i].actas_extraordinarias[j].url_archivo;
          }
          for (let j = 0; j < this.actasSesion[i].documentos.length; j++) {
            this.actasSesion[i].documentos[j].url_archivo =
              base + this.actasSesion[i].documentos[j].url_archivo;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.actasSesion = [];
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "concejo-municipal", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          "concejo" == this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }
}
