








import { Component, Vue } from "vue-property-decorator";
import Seguridad from "@/components/Municipalidad/Seguridad.vue";

@Component({
  components: {
    Seguridad
  }
})
export default class SeguridadView extends Vue {
  private mounted() {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/municipio/seguridad";
    document.head.appendChild(canonicalLink);
  }
}
