import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "WebLayout" */ "@/layouts/WebLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false,
          title: "Inicio- Municipalidad de Arica"
        }
      },
      // {
      //   path: "/tramite-licencia-de-conducir",
      //   name: "Licencia",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "videos" */ "@/views/Web/LicenciaView.vue"
      //     ),
      //   meta: {
      //     requiresAuth: false,
      //     title: "Trámite licencia de conducir - Municipalidad de Arica"
      //   }
      // },
      {
        path: "/videos",
        name: "Videos",
        component: () =>
          import(
            /* webpackChunkName: "videos" */ "@/views/Videos/VideosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Videos - Municipalidad de Arica"
        }
      },
      {
        path: "/actividades",
        name: "Actividades",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Actividades/GeneralView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Actividades - Municipalidad de Arica"
        }
      },
      {
        path: "/actividades/categoria/:nombre",
        name: "CategoriaActividad",
        component: () =>
          import(
            /* webpackChunkName: "CategoriaActividad" */ "@/views/Actividades/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Actividades - Municipalidad de Arica"
        }
      },
      {
        path: "/actividades/publicacion/:nombre",
        name: "PublicacionAct",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Actividades/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Actividad -Municipalidad de Arica"
        }
      },
      {
        path: "/cultura",
        name: "Cultura",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/cultura/CulturaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Cultura -Municipalidad de Arica"
        }
      },
      {
        path: "/tramites-municipales",
        name: "TramitesMunicipales",
        component: () =>
          import(
            /* webpackChunkName: "TramitesMunicipales" */ "@/views/TramitesMunicipales/TramitesMunicipalesView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Trámites Municipales -Municipalidad de Arica"
        }
      },
      {
        path: "/cultura/categoria/:nombre",
        name: "CategoriaCultura",
        component: () =>
          import(
            /* webpackChunkName: "CategoriaCultura" */ "@/views/cultura/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Cultura - Municipalidad de Arica"
        }
      },
      {
        path: "/cultura/publicacion/:nombre",
        name: "PublicacionCul",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/cultura/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Cultura -Municipalidad de Arica"
        }
      },
      {
        path: "/mascotas",
        name: "Mascotas",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/mascotas/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Mascotas -Municipalidad de Arica"
        }
      },
      {
        path: "/mascotas/:nombre",
        name: "PublicacionMas",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/mascotas/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Mascotas -Municipalidad de Arica"
        }
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Noticias/NoticiasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Noticias - Municipalidad de Arica"
        }
      },

      {
        path: "/medio-ambiente",
        name: "medio-ambiente",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/MedioAmbienteView.vue"
          ),
        meta: {
          title: "Medio Ambiente - Municipalidad de Arica"
        }
      },
      {
        path: "/medio-ambiente/quienes-somos",
        name: "QuienesSomos",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/QuienesSomosView.vue"
          ),
        meta: {
          title: "Medio Ambiente | Quiénes Somos - Municipalidad de Arica"
        }
      },
      {
        path: "/medio-ambiente/que-hacemos",
        name: "QueHacemos",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/QueHacemosView.vue"
          ),
        meta: {
          title: "Medio Ambiente | Qué Hacemos - Municipalidad de Arica"
        }
      },
      {
        path: "/medio-ambiente/documentos-de-interes",
        name: "DocumentosInteres",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/DocumentosInteresView.vue"
          ),
        meta: {
          title:
            "Medio Ambiente | Documentos de Intéres - Municipalidad de Arica"
        }
      },
      {
        path: "/medio-ambiente/cac",
        name: "CAC",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/CacView.vue"
          ),
        meta: {
          title: "Medio Ambiente | CAC - Municipalidad de Arica"
        }
      },
      {
        path: "/medio-ambiente/cam",
        name: "CAM",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/CamView.vue"
          ),
        meta: {
          title: "Medio Ambiente | CAM - Municipalidad de Arica"
        }
      },
      {
        path: "/noticias/publicacion/:nombre",
        name: "Publicacion",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Noticias/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia - Municipalidad de Arica"
        }
      },
      {
        path: "/contenido/noticias/preview/:nombre",
        name: "PublicacionPreview",
        component: () =>
          import(
            /* webpackChunkName: "PublicacionPreview" */ "@/views/Noticias/PublicacionPreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia Preview - Municipalidad de Arica"
        }
      },
      {
        path: "/noticias/categoria/:nombre",
        name: "Categoria",
        component: () =>
          import(
            /* webpackChunkName: "Categoria" */ "@/views/Noticias/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias - Municipalidad de Arica"
        }
      },
      {
        path: "/arica-segura",
        name: "Seguridad",
        component: () =>
          import(
            /* webpackChunkName: "seguridad" */ "@/views/Municipalidad/SeguridadView.vue"
          ),
        meta: {
          title: "Seguridad - Municipalidad de Arica"
        }
      },
      {
        path: "/:nombre",
        name: "Pagina",
        component: () =>
          import(
            /* webpackChunkName: "Pagina" */ "@/views/Pagina/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Municipalidad de Arica"
        }
      },
      {
        path: "/contenido/pagina/preview/:nombre",
        name: "PaginaPreview",
        component: () =>
          import(
            /* webpackChunkName: "PaginaPreview" */ "@/views/Pagina/PreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Preview Página - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/servicios",
        name: "Servicios",
        component: () =>
          import(
            /* webpackChunkName: "Servicios" */ "@/views/Servicios/ServiciosView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Pagos y Servicios- Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/boletines",
        name: "Boletines",
        component: () =>
          import(
            /* webpackChunkName: "oferta" */ "@/views/Web/BoletinesView.vue"
          ),
        meta: {
          title: "Boletines - Municipalidad de Maipú"
        }
      },
      {
        path: "/formulario/contacto/:nombre/:id",
        name: "FormularioContacto",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/FormularioGeneralView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Formulario de Contacto - Municipalidad de Arica"
        }
      },
      {
        path: "/reserva/:nombre",
        name: "Reserva",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/ReservaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Reservas - Municipalidad de Arica"
        }
      },
      {
        path: "/reserva/ticket/:codigo",
        name: "ReservaTicket",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/RevisarReservaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Reservas - Municipalidad de Arica"
        }
      },
      {
        path: "/reserva/:nombre/anular",
        name: "AnularReserva",
        component: () =>
          import(
            /* webpackChunkName: "AnularReserva" */ "@/views/Reserva/AnularReservaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Reservas - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/reserva",
        name: "ListaReservas",
        component: () =>
          import(
            /* webpackChunkName: "ListaReservas" */ "@/views/Reserva/ListaReservasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Reservas - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/ofertas",
        name: "OfertasOmil",
        component: () =>
          import(
            /* webpackChunkName: "oferta" */ "@/views/Web/OfertasOmilView.vue"
          ),
        meta: {
          title: "Bolsa de Empleo - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/avisos",
        name: "MunicipioInforma",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Servicios/MunicipioInformaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Municipio Informa - Ilustre Municipalidad de Arica"
        }
      },
      {
        path: "/listado/encuestas",
        name: "EncuestasList",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Encuesta/EncuestasListView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Listado de Encuentas - Municipalidad de Arica"
        }
      },
      {
        path: "/encuesta/:nombre",
        name: "Encuesta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Encuesta/EncuestaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Encuentas - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/concejo",
        name: "Concejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcejoView.vue"
          ),
        meta: {
          title: "Concejo Municipal - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/agenda-alcalde",
        name: "AgendaAlcalde",
        component: () =>
          import(
            /* webpackChunkName: "AgendaAlcalde" */ "@/views/Municipalidad/AgendaAlcaldeView.vue"
          ),
        meta: {
          title: "Agenda Alcalde - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/oficinas-y-telefonos-municipales",
        name: "Telefonos",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Web/TelefonosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Oficinas y Teléfonos - Municipalidad de Arica"
        }
      },
      {
        path: "/municipio/concursos-publicos",
        name: "ConcursosPublicos",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcursosPublicosView.vue"
          ),
        meta: {
          title: "ConcursosPublicos - Municipalidad de Arica"
        }
      },
     
      // {
      //   path: "/municipio/actas-concejo",
      //   name: "ActasConcejo",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ActasConcejoView.vue"
      //     ),
      //   meta: {
      //     title: "Actas de Concejo - Municipalidad de Arica"
      //   }
      // },
      // {
      //   path: "/municipio/ordenanzas",
      //   name: "Ordenanzas",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ordenanzas" */ "@/views/Municipalidad/OrdenanzasView.vue"
      //     ),
      //   meta: {
      //     title: "Ordenanzas - Municipalidad de Arica"
      //   }
      // },
      // {
      //   path: "/municipio/cosoc",
      //   name: "Cosoc",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "cosoc" */ "@/views/Municipalidad/CosocView.vue"
      //     ),
      //   meta: {
      //     title:
      //       "Consejo Comunal de Organizaciones de la Sociedad Civil - Municipalidad de Arica"
      //   }
      // },
      {
        path: "/formulario/medio-ambiente",
        name: "MedioAmbiente",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioInscripcionView.vue"
          ),
        meta: {
          title:
            "Inscripción Seminario Día Mundial del Medio Ambiente - Municipalidad de Arica"
        }
      },
      // {
      //   path: "/formulario/postulacion",
      //   name: "Postulacion",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioPostulacionView.vue"
      //     ),
      //   meta: {
      //     title: "Trabaja con Nosotros - Municipalidad de Arica"
      //   }
      // },
      {
        path: "/municipio/galerias",
        name: "Galerias",
        component: () =>
          import(
            /* webpackChunkName: "galerias" */ "@/views/Galeria/GaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galerías - Municipalidad de Arica"
        }
      },
      {
        path: "/galeria/:nombre",
        name: "DetalleGaleria",
        component: () =>
          import(
            /* webpackChunkName: "detallegaleria" */ "@/views/Galeria/DetalleGaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galeria - Municipalidad de Arica"
        }
      },

      { path: "/:pathMatch(.*)", redirect: { name: "Home" } }

      // { path: '/:pathMatch(.*)', redirect: { name: 'Home' } }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
  if ("speechSynthesis" in window) {
    window.speechSynthesis.cancel();
  }
});

export default router;
