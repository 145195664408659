

























































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class Cac extends Vue {
  private menuLeft: any = false;
  private activeItem: any = "";

  @Watch("$route", { immediate: true, deep: true })
  private changeRoute(newValue: any) {
    if (this.$route && this.$route.name) {
      console.log(this.$route);
      if (this.$route.name == "CAC") {
        this.activeItem = "CAC";
      }
    } else {
      console.log("Route or route name is undefined");
    }
  }

  private toLink(item: string, target: string) {
    if (item) {
      window.open(item, target);
    }
  }

  private routerGo(name: any) {
    if (this.$router.currentRoute.name == name) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: name
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
  private enviarEmail(email) {
    let mailtoLink = "mailto:" + email;
    window.location.href = mailtoLink;
  }
  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }
}
